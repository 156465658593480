/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const Mail: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask id='mask0_169_48590' style={{
      maskType: "alpha"
    }} maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
                <rect width='24' height='24' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_169_48590)'>
                <path d='M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM20 8L12.525 12.675C12.4417 12.725 12.354 12.7623 12.262 12.787C12.1707 12.8123 12.0833 12.825 12 12.825C11.9167 12.825 11.8293 12.8123 11.738 12.787C11.646 12.7623 11.5583 12.725 11.475 12.675L4 8V18H20V8ZM12 11L20 6H4L12 11ZM4 8.25V6.775V6.8V6.787V8.25Z' fill='white' />
            </g>
        </svg>;
};

export default Mail;